.banner {
  position: absolute;
  right: 25px;
  top: 80px;
  z-index: 99;
  height: 140px;
  width: 249px;
  border-radius: 1px;
  background-color: #fbfbfb;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1100px) {
    top: 25px;
  }

  p {
    margin: 0 10px;
    height: 75px;
    width: 224px;
    color: #303030;
    font-family: ProximaNova;
    font-size: 16px;
    letter-spacing: -0.16px;
    line-height: 21px;
    text-align: center;
  }

  a {
    height: 40px;
    width: 216px;
    border-radius: 1px;
    background-color: #5cbe80;
    text-decoration: none;
    text-align: center;
    color: #ffffff;
    font-family: ProximaNova;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.9px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: #ffffff;
    }
  }
}
