.chapterInfo {
  width: 376px;
  font-family: ProximaNova;
}

.chapterTitle {
  display: flex;
  flex-direction: column;
  border-bottom: #d8d8d8 solid 0.25px;
  padding-bottom: 20px;
}

.title {
  font-family: ProximaNova_Semibold;
  color: #303030;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 18px;
  font-size: 22px;
  letter-spacing: 1.24px;
  line-height: 25px;
  font-weight: 600;
}

.date {
  color: #303030;
  font-family: ProximaNova;
  font-size: 13.5px;
  margin-left: 25px;
  letter-spacing: 1.34px;
}

.chapterStats {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: #d8d8d8 solid 0.25px;
  padding: 10px 0px;
}

.volunteers {
  font-family: ProximaNova_Semibold;
  display: flex;
  flex-direction: column;
  padding-right: 24px;
  border-right: #d8d8d8 solid 0.1px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.9px;
}

.reunions {
  font-family: ProximaNova_Semibold;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.9px;
  margin-bottom: -1rem;
}

.number {
  font-family: ProximaNova_Bold;
  align-self: center;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.9px;
  margin-left: 1px;
}

.chapterDetails {
  padding: 20px 25px 0px 25px;
  font-size: 13px;
  letter-spacing: 0.73px;
  line-height: 18px;
}

.contact {
  font-family: ProximaNova_Bold;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-weight: bold;
  letter-spacing: 0.73px;
  font-size: 13px;
  border-bottom: #d8d8d8 solid 0.25px;
}

.emailHeader {
  font-family: ProximaNova_Semibold;
  margin: 20px 0 0 25px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.9px;
  line-height: 20px;
}

.gmailLogo {
  width: 18px;
  padding-right: 10px;
}

.buttons {
  font-family: ProximaNova_Semibold;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 10px;
  font-size: 16px;
  border-bottom: #d8d8d8 solid 0.25px;
  padding-bottom: 20px;
}

.green {
  width: 253px;
  height: 40px;
  margin: 15px 0px;
  background: #5cbe80;
  border: 1px #5cbe80 solid;
  border-radius: 1px;
}

.green a {
  color: white;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.9px;
  text-align: center
}

.white {
  width: 253px;
  height: 40px;
  background: white;
  border: 1px #5cbe80 solid;
  border-radius: 1px;
}

.white a {
  color: #5cbe80;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.9px;
}

.featuredReunion {
  font-family: ProximaNova;
  padding: 0px 25px 10px;
  font-size: 13px;
  letter-spacing: 0.73px;
  line-height: 16px;
  border-bottom: #d8d8d8 solid 0.25px;
}

.featuredReunion h2 {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.9px;
  line-height: 20px;
}

.reunionStory {
  padding-top: 15px;
  padding-bottom: 25px;
}

.stories {
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.73px;
  line-height: 16px;
  text-decoration: none;
  color: black;
}

.sponsors,
.partners {
  border-bottom: #d8d8d8 solid 0.25px;
}

.sponsors h2,
.partners h2 {
  margin: 10px 0 10px 25px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.9px;
  line-height: 20px;
}

.icons-container {
  margin-left: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.icon-container {
  height: 75px;
  width: 75px;
  margin: 0 35px 5px 0;
  margin-bottom: 20px;
}

.icon-container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
