html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.App {
  box-sizing: border-box;
  width: 100%;
}
/* Popup Style */

@font-face {
  font-family: ProximaNova;
  src: url('../Assets/ProximaNova.otf');
}

@font-face {
  font-family: ProximaNova_Bold;
  src: url('../Assets/ProximaNova_Bold.otf');
}

@font-face {
  font-family: ProximaNova_Semibold;
  src: url('../Assets/ProximaNova_Semibold.otf');
}

.mapboxgl-popup {
  /* height: 203px; */
  /* width: 275px; */
  text-align: center;
  cursor: auto;
}

.mapboxgl-popup-content {
  /* height: 183px; */
  width: 275px;
  background: #484747 !important;
  color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5) !important;
}

.mapboxgl-popup-tip {
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  /* border-top: 10px solid transparent !important;
  border-bottom: 10px solid transparent !important; */
}

/* Tip Color */

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  margin-top: 18px !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #484747 !important;
  /* height: 30px; To be used later */
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #484747 !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  /* border-right-color: #484747 !important; */
  border-top: 10px solid transparent !important;
  border-bottom: 10px solid transparent !important;
  border-right: 20px solid #484747 !important;
  margin: 15px -2px 0 0;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  /* border-left-color: #484747 !important; */
  border-top: 10px solid transparent !important;
  border-bottom: 10px solid transparent !important;
  border-left: 20px solid #484747 !important;
  margin: 15px 0 0 -2px;
}

.mapboxgl-popup-close-button {
  color: whitesmoke !important;
  font-size: 20px;
  /* margin: 2px 5px 5px 5px; */
  /* border: 1px solid white; */
  background: transparent !important;
  width: 40px;
  height: 40px;
  outline: none;
}

.mapboxgl-popup-close-button:hover {
  font-size: 26px;
  /* transition: 0.5s; */
  /* transform: rotate(-90deg);
  transition: transform 650ms ease; */
  color: white !important;
  /* text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white; */
}

.info {
  font-size: 18px;
  line-height: 10px;
  letter-spacing: -0.36px;
  text-align: left;
  margin-left: 15px;
}

.info h3 {
  margin-top: -3px;
  margin-bottom: 20px;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 1.54px;
  line-height: 24px;
  text-align: center;
}

.info a {
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  margin: 8px 8px 8px 0;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
}

.info a:hover {
  text-decoration: underline;
}

.info a span img {
  width: 20px;
}

.info .arrow {
  position: absolute;
  top: 8px;
  font-size: 22px;
}

.info .arrow:hover {
  color: #484747;
  text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
  cursor: pointer;
}
